@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
  @apply mx-auto max-w-[1362px] px-4 w-full;
}

.text-gradient-primary {
  @apply bg-gradient-primary bg-clip-text text-transparent
}

.text-gradient-primary-top {
  @apply bg-gradient-primary bg-clip-text text-transparent
}

.text-gradient-secondary {
  @apply bg-gradient-secondary bg-clip-text text-transparent
}

.text-gradient-background {
  @apply bg-gradient-background bg-clip-text text-transparent
}

.text-gradient-text {
  @apply bg-gradient-text bg-clip-text text-transparent
}

html {
  @apply scroll-smooth
}

/* Flip card */
.flip-card {
  perspective: 1000px;
}

.flip-card-inner {
  transform-style: preserve-3d;
}

.flip-card-inner.flipped {
  transform: rotateY(-180deg);
}

.flip-card-front, .flip-card-back {
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(-180deg);
}
